import {createReducer} from '@reduxjs/toolkit'
import {CHANGE_EVENT} from '../actions/event'
import {selectTicket, unselectTicket, changeTicketDonation, setDonationError} from '../actions/selected-tickets'
import {SelectedTickets} from '../types'

const defaultState: SelectedTickets = {}

export const selectedTickets = createReducer(defaultState, builder => {
  builder
    .addCase(selectTicket, (state, {payload: {ticketId, count}}) => {
      return {
        ...state,
        [ticketId]: {
          ...state[ticketId],
          quantity: count,
        },
      }
    })
    .addCase(unselectTicket, (state, {payload: {ticketId, count}}) => ({
      ...state,
      [ticketId]: {
        ...state[ticketId],
        quantity: count,
        donation: count ? state[ticketId]?.donation : undefined,
      },
    }))
    .addCase(changeTicketDonation, (state, {payload: {ticketId, donation, minimum}}) => ({
      ...state,
      [ticketId]: {
        ...state[ticketId],
        donation,
        quantity: donation === undefined || donation < minimum ? 0 : state[ticketId]?.quantity || 1,
        donationError: undefined,
      },
    }))
    .addCase(setDonationError, (state, {payload: {ticketId, error}}) => ({
      ...state,
      [ticketId]: {
        ...state[ticketId],
        donationError: error,
      },
    }))
    .addCase(CHANGE_EVENT, () => defaultState)
})
