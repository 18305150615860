import {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {
  GROUPS_APP_DEF_ID,
  GROUPS_SECTION_ID,
  isRtlLanguage,
  PAID_PLANS_APP_DEF_ID,
  PAID_PLANS_SECTION_ID,
} from '@wix/wix-events-commons-statics'
import {ReservationStatus} from '@wix/events-types'
import {createEventHandler} from '@wix/yoshi-flow-editor/tpa-settings'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {watchInstance} from '../../commons/actions/instance'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {decodeInstance} from '../../commons/selectors/instance'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getPageUrl, isSchedulePageInstalled} from '../../commons/utils/wix-code-api'
import {createUouBiMiddlewareWithBiParams} from '../../commons/bi/bi'
import {SiteSettings} from '../../commons/types/state'
import {DetailsPageSettingsEventsKeys, DetailsSettingsNavigateActions, SettingsEvents} from './Widget/constants'
import {getExtraEventData} from './Widget/actions/event'
import {getEventsMembersPageInfo} from './Widget/actions/events-members-page'
import {addLoginListener, fetchCurrentMember} from './Widget/actions/members'
import {addLocationListener, handleInitialNavigation, navigate} from './Widget/actions/navigation'
import {getMemberRsvp} from './Widget/actions/rsvp'
import * as eventsUou from './Widget/bi/uou-bi-events-map'
import {DetailsPageProps} from './Widget/components/app/interfaces'
import {datesMiddleware} from './Widget/middlewares/date'
import reducers from './Widget/reducers'
import {defaultInvoiceState} from './Widget/reducers/invoice'
import {getDemoEvent} from './Widget/services/demo-event'
import type {Navigation, SelectedTicket, State, StoreExtraArgs} from './Widget/types'
import {userEventsLogger} from './Widget/user-events-logger'
import {Api} from './Widget/utils/api'
import {parseLocation} from './Widget/utils/navigation'
import {getDraftToken} from './Widget/utils/query'
import {getExportedActions} from './Widget/actions/exported-actions'
import {openSeatingPlan} from './Widget/actions/modals'

const createDetailsPageController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerParams.controllerConfig.config.publicData.COMPONENT || {},
  )

  return {
    updateConfig: (_, newConfig) => {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
    },
    async pageReady() {
      const {wixCodeApi} = controllerParams.controllerConfig
      const {flowAPI} = controllerParams
      const language = getLanguage(wixCodeApi)
      const ssr = flowAPI.environment.isSSR
      const experiments = flowAPI.experiments.all()

      const serverApi = new Api(controllerParams)
      const controller = controllerParams.controllerConfig

      const [pageUrl, initialData, paidPlansEnabled, membersAreaEnabled, groupsInstalled] = await Promise.all([
        getPageUrl(wixCodeApi),
        getInitialData(serverApi, controller),
        wixCodeApi.site.isAppSectionInstalled({
          appDefinitionId: PAID_PLANS_APP_DEF_ID,
          sectionId: PAID_PLANS_SECTION_ID,
        }),
        isMembersEventsPageInstalled(wixCodeApi),
        wixCodeApi.site.isAppSectionInstalled({
          appDefinitionId: GROUPS_APP_DEF_ID,
          sectionId: GROUPS_SECTION_ID,
        }),
      ])

      const store = createStore(
        controllerParams,
        {
          ...initialData,
          experiments,
          membersAreaEnabled,
          paidPlansEnabled,
          groups: {installed: groupsInstalled},
        },
        serverApi,
      )

      store.dispatch(setBaseEnvironment() as any)
      if (wixCodeApi.location.query.chooseSeat) {
        await store.dispatch(openSeatingPlan() as any)
      }
      await store.dispatch(fetchCurrentMember() as any)
      await store.dispatch(getMemberRsvp() as any)

      store.dispatch(addLoginListener() as any)

      watchInstance(controller, store.dispatch)

      await store.dispatch(handleInitialNavigation() as any)

      addLocationListener(controller.wixCodeApi, store)

      const actions = getExportedActions(store)

      const props: DetailsPageProps = {
        state: store.getState(),
        actions,
        isRTL: isRtlLanguage(language),
        staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
        pageUrl,
        // @ts-expect-error
        fitToContentHeight: true,
      }

      controller.setProps(props)
      subscribeToStateChanges(controller, store)

      if (!ssr) {
        const event = store.getState().event
        store.dispatch(getExtraEventData(event) as any)
        store.dispatch(getEventsMembersPageInfo() as any)
      }

      componentEventHandler.on(
        DetailsPageSettingsEventsKeys.Navigate,
        (navigateAction: DetailsSettingsNavigateActions) => {
          if (navigateAction === DetailsSettingsNavigateActions.details) {
            store.dispatch(navigate(DETAILS_ROUTE.DETAILS) as any)
          }
          if (navigateAction === DetailsSettingsNavigateActions.form) {
            store.dispatch(navigate(DETAILS_ROUTE.FORM) as any)
          }
        },
      )
    },
  }
}

const createStore = (controllerParams: ControllerParams, initialData: any, serverApi: any) => {
  const {controllerConfig: controller} = controllerParams
  const {wixCodeApi, compId, platformAPIs, appParams} = controller

  const biMiddleware = [
    createUouBiMiddlewareWithBiParams(
      {
        wixCodeApi,
        platformAPIs,
        appParams,
        compId,
        user: {
          aid: initialData.instance.aid,
          uid: initialData.instance.uid,
        },
      },
      eventsUou,
    ),
  ]

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  return createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: {...initialData, ...(controller as any).testState},
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
      platformAPIs,
      flowAPI: controllerParams.flowAPI,
    },
    middleware: [...biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })
}

const getInitialData = async (
  serverApi: Api,
  controller: ControllerParams['controllerConfig'],
): Promise<Partial<State>> => {
  const {wixCodeApi, appParams, config} = controller
  const navigation = parseLocation(wixCodeApi)
  const {slug} = navigation
  const responsiveEditor = isResponsiveEditor(config)

  const schedulePageInstalled = await isSchedulePageInstalled(wixCodeApi)
  const currentUser = wixCodeApi.user.currentUser

  const {event, component, siteSettings, demoEvents, tickets, schedule, dates, upcomingOccurrencesCount} =
    await serverApi.getData({
      slug,
      responsive: responsiveEditor,
      schedulePageInstalled,
      draftPreviewToken: getDraftToken(wixCodeApi),
    })
  const instance = appParams.instance

  const invoiceState = await getStateFromInvoice({serverApi, event, navigation})

  return {
    event: !event && demoEvents ? getDemoEvent(demoEvents, slug, responsiveEditor) : event,
    siteSettings: siteSettings as SiteSettings,
    demoEvents,
    tickets,
    schedule,
    upcomingOccurrencesCount,
    multilingual: getMultilingualInitialState(wixCodeApi),
    navigation,
    component: {
      id: component.id,
      settings: component.config.settings as any,
    },
    instance: {
      instance,
      ...decodeInstance(instance),
    },
    policies: {
      agreed: false,
      policies: [],
      policyAgreementToken: null,
      showAlert: false,
    },
    dates,
    currentUser: {
      id: currentUser.id,
      role: currentUser.role,
      loggedIn: currentUser.loggedIn,
    },
    ...invoiceState,
  }
}

const getStateFromInvoice = async ({
  serverApi,
  event,
  navigation,
}: {
  serverApi: Api
  event: wix.events.Event
  navigation: Navigation
}) => {
  if (navigation.route === DETAILS_ROUTE.TICKET_FORM && event?.id) {
    const {
      query: {reservationId},
    } = navigation

    if (reservationId && reservationId !== 'undefined') {
      const invoice = await serverApi.getInvoice(event.id, reservationId)

      if (invoice.reservationStatus === ReservationStatus.RESERVATION_PENDING) {
        const selectedTickets = invoice.invoice.items.reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: {quantity: item.quantity} as SelectedTicket,
          }),
          {} as Record<string, SelectedTicket>,
        )

        return {
          invoice: {
            ...defaultInvoiceState,
            invoice: invoice.invoice,
          },
          selectedTickets,
          reservation: {
            data: {
              id: reservationId,
              expires: invoice.expires,
            },
            error: null,
          },
        }
      }

      return {}
    }
  }

  return {}
}

export default createDetailsPageController
