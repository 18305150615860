import {createAction} from '@reduxjs/toolkit'
import {DONATION_ERROR} from '../types'

export interface SelectUnselectTicket {
  ticketId: string
  count: number
}

export interface ChangeTicketDonation {
  ticketId: string
  donation: number
  minimum: number
}

export interface SetDonationError {
  ticketId: string
  error: DONATION_ERROR
}

export const selectTicket = createAction<SelectUnselectTicket>('SELECT_TICKET')

export const unselectTicket = createAction<SelectUnselectTicket>('UNSELECT_TICKET')

export const changeTicketDonation = createAction<ChangeTicketDonation>('CHANGE_TICKET_DONATION')

export const setDonationError = createAction<SetDonationError>('SET_DONATION_ERROR')
